<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import { TransitionPresets, useTransition, useElementVisibility } from '@vueuse/core';

const props = defineProps({
    viewBox: {
        type: String,
        default: '0 0 0 0',
    },
    offset: {
        type: Number,
        default: 0,
    },
    delay: {
        type: Number,
        default: 0,
    },
    duration: {
        type: Number,
        default: 1000,
    },
});

const el = ref(null);
const elVisibility = useElementVisibility(el);

const strokeOffset = ref(props.offset);
const strokeOffsetTransition = useTransition(strokeOffset, {
    duration: props.duration,
    delay: props.delay,
    transition: TransitionPresets.easeInCubic,
});

watchEffect(() => {
    if (elVisibility.value) {
        strokeOffset.value = 0;
    }
});
</script>

<template>
    <svg ref="el" class="w-full" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            v-bind="$attrs"
            :stroke-dashoffset="strokeOffsetTransition"
        />
    </svg>
</template>
