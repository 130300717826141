import {
    defineComponent,
    h,
    ref,
    watch,
} from 'vue';

import { refDebounced } from '@vueuse/core';

import { useId } from '@composables/use-id';

export const Carousel = defineComponent({
    name: 'Carousel',

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    setup(props, { slots }) {
        const items = ref(props.items);
        const activeItemIndex = ref(0);

        const calculateItemIndex = (action) => {
            if (typeof action !== 'string') {
                return action;
            }

            switch (action) {
                case 'First':
                    return 0;
                case 'Last':
                    return items.value.length - 1;
                case '>':
                    return activeItemIndex.value !== items.value.length - 1 ? activeItemIndex.value + 1 : 0;
                case '<':
                    return activeItemIndex.value !== 0 ? activeItemIndex.value - 1 : items.value.length - 1;
                default:
                    return 0;
            }
        };

        const goTo = (action) => {
            activeItemIndex.value = calculateItemIndex(action);
        };

        watch(activeItemIndex, (newValue, oldValue) => {
            items.value[oldValue].active = false;
            items.value[newValue].active = true;
        });

        return () => {
            return h('div', {}, slots.default({
                items: items.value,
                index: activeItemIndex.value,
                goTo,
            }));
        };
    },
});
