import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useItineraryStore = defineStore('itinerary', () => {
    const items = useLocalStorage('itinerary/items', []);

    const findItem = (item) => {
        return items.value.some((currentItem) => {
            return currentItem.id === item.id;
        });
    };

    const addItem = (item) => {
        if (findItem(item)) {
            return;
        }

        items.value.push(item);
    };

    const removeItem = (itemId) => {
        items.value = items.value.filter((item) => {
            return item.id !== itemId;
        });
    };

    return {
        items,
        findItem,
        addItem,
        removeItem,
    };
});
