// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

// Update the CSRF field on Formie forms
import formieCsrf from './components/formie-csrf';

// ================================================
// SUI Components
// ================================================


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Popover,
    PopoverButton,
    PopoverPanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import SplideCarousel from '@components/SplideCarousel.vue';

import {
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';

import DialogTrigger from '@components/DialogTrigger.vue';
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';
import ItineraryAddButton from '@components/ItineraryAddButton.vue';
import ItineraryList from '@components/ItineraryList.vue';
import LineAnimation from '@components/LineAnimation.vue';
import { Carousel } from '@components/Carousel';
import EventsList from '@components/EventsList.vue';
import LazyHeader from '@components/LazyHeader.vue';

const pinia = createPinia();

const app = createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiPopover: Popover,
        HuiPopoverButton: PopoverButton,
        HuiPopoverPanel: PopoverPanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        HuiTransitionRoot: TransitionRoot,
        HuiTransitionChild: TransitionChild,
        SuiCarousel: Carousel,
        SplideCarousel,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        DialogTrigger,
        GoogleMapEmbed,
        ItineraryAddButton,
        ItineraryList,
        LineAnimation,
        EventsList,
        LazyHeader,
    },

    data() {
        return {
            navActive: false,
            searchActive: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.
        formieCsrf();
    },

    methods: {
        toggleSearch() {
            this.searchActive = !this.searchActive;

            this.$nextTick(() => {
                if (this.searchActive) {
                    const searchInput = document.querySelector('#search');

                    console.log(searchInput);

                    searchInput.focus();
                }
            });
        },
    },
});

app.use(pinia);
app.mount('#app');
