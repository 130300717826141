<script setup>
import { storeToRefs } from 'pinia';
import { useItineraryStore } from '@stores/itinerary';
import GoogleMapEmbed from '@components/GoogleMapEmbed.vue';

const itinerary = useItineraryStore();

const { items } = storeToRefs(itinerary);

const removeItem = (itemId) => {
    itinerary.removeItem(itemId);
};
</script>

<template>
    <div class="lg:flex">
        <div class="flex flex-col lg:w-[400px] p-8 bg-brandLightCream">
            <h1 class="h2 pb-4">My Itinerary</h1>

            <div class="relative flex-grow">
                <div class="lg:absolute lg:inset-0 lg:overflow-y-auto">
                    <ul class="grid grid-cols-1 auto-rows-max divide-y divide-black">
                        <li v-for="item in items" :key="item.id" class="flex gap-4 items-center py-4">
                            <div class="aspect aspect-1x1 w-14 flex-shrink-0">
                                <img :src="item.image" :alt="item.title">
                            </div>

                            <div class="flex-grow">
                                <div class="font-heading text-lg leading-none">{{ item.title }}</div>

                                <button class="font-heading text-xs text-red-800 uppercase" type="button" @click.prevent="removeItem(item.id)">Remove</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="flex-grow">
            <GoogleMapEmbed
                :markers="items"
                fit-bounds
                hide-labels
                class="relative z-10 aspect aspect-4x3"
            />
        </div>
    </div>
</template>
