<script setup>
import {
    ref, reactive, watch, computed,
} from 'vue';
import { useFetch } from '@vueuse/core';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const props = defineProps({
    categories: {
        type: Array,
        default: () => {
            return [];
        },
    },
});

const query = reactive({
    query: `query Events($eventDate: [QueryArgument], $relatedTo: [QueryArgument], $limit: Int) {
                entryCount(
                    section: "events", 
                    eventDate: $eventDate, 
                    relatedTo: $relatedTo, 
                    siteId: "1", 
                    limit: $limit,
                    orderBy: "eventDate asc"
                )
                entries(
                    section: "events", 
                    eventDate: $eventDate, 
                    relatedTo: $relatedTo, 
                    siteId: "1", 
                    limit: $limit,
                    orderBy: "eventDate asc"
                ) {
                    id
                    title
                    url
                    ... on events_event_Entry {
                        eventImages(limit: 1) {
                            id
                            title
                            alt
                            url(transform: "medium")
                            focalPoint
                        }
                        eventDate @formatDateTime(format: "jS F Y", timezone: "Australia/Hobart")
                        shortDescriptionParsed
                        location {
                            lat
                            lng
                            parts {
                                number
                                address
                                city
                            }
                        }
                    }
                }
            }`,
    variables: {
        eventDate: '>= now',
        limit: 9,
    },
});

const {
    isFetching, error, execute: refetch, data,
} = useFetch('api', {
    beforeFetch({ options }) {
        options.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };

        options.body = JSON.stringify(query);

        return { options };
    },
}).post().json();

const dates = ref([]);

watch(dates, (value) => {
    query.variables.limit = 9;

    if (!value) {
        query.variables.eventDate = ':notempty:';
        refetch();
        return;
    }

    const [startDate, endDate] = value;

    console.log(startDate);

    query.variables.eventDate = ['and', `>= ${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`, `< ${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate() + 1).padStart(2, '0')}`];

    refetch();
});

const relatedTo = ref([]);

watch(relatedTo, (value) => {
    query.variables.limit = 9;

    if (!value.length) {
        delete query.variables.relatedTo;
        refetch();
        return;
    }

    query.variables.relatedTo = ['and', ...value];

    refetch();
});

const incLimit = () => {
    query.variables.limit = query.variables.limit + 9;
    refetch();
};
</script>

<template>
    <div>
        <div class="container relative z-40 py-12">
            <div class="flex flex-col lg:flex-row gap-6 lg:gap-12">
                <div class="flex flex-col gap-1 w-80">
                    <label class="form-label font-semibold uppercase text-white text-sm lg:text-base mb-0" for="dates">Event date</label>

                    <hr class="border-white mb-2">

                    <VueDatePicker id="dates" v-model="dates" :min-date="new Date()" :enable-time-picker="false" range auto-apply>
                        <template #trigger>
                            <button v-if="dates[0] && dates[1]" class="flex w-full gap-2 text-left">
                                <span class="flex-grow py-2 px-4 bg-white/80 text-brandDarkBurgundy">
                                    From <span class="font-semibold">{{ new Intl.DateTimeFormat('en-AU', { day: '2-digit', month: 'short', year: 'numeric' }).format(dates[0]) }}</span> to <span class="font-semibold">{{ new Intl.DateTimeFormat('en-AU', { day: '2-digit', month: 'short', year: 'numeric' }).format(dates[1]) }}</span>
                                </span>
                            </button>

                            <button v-else class="py-2 px-4 bg-white/80 text-brandDarkBurgundy w-full text-left text-sm lg:text-base">
                                Select dates
                            </button>
                        </template>
                    </VueDatePicker>
                </div>

                <div class="flex flex-grow flex-col gap-1">
                    <label class="form-label font-semibold uppercase text-white text-sm lg:text-base mb-0" for="dates">Filters</label>

                    <hr class="border-white mb-2">

                    <div class="flex flex-col items-start lg:flex-row gap-6 lg:gap-3">
                        <div class="flex flex-grow gap-3">
                            <div v-for="category in categories" :key="category.id" class="relative overflow-hidden">
                                <input :id="`category-${category.id}`" v-model="relatedTo" type="checkbox" :value="category.id" class="peer absolute -top-8 -left-8">
                                <label :for="`category-${category.id}`" class="block py-2 px-4 bg-white/80 peer-checked:bg-black/50 text-brandDarkBurgundy peer-checked:text-white font-semibold text-sm lg:text-base cursor-pointer whitespace-nowrap">{{ category.title }}</label>
                            </div>
                        </div>

                        <a href="/events/listings" class="btn btn-white flex items-center text-brandDarkBurgundy hover:text-brandDarkBurgundy">See more filters</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- <slot :entries="data?.data.entries" :entry-count="data?.data.entryCount" :limit="query.variables.limit" :inc-limit="incLimit" :is-fetching="isFetching"></slot> -->
        <slot :entries="data?.data?.entries ? data.data.entries : []" :entry-count="data?.data.entryCount" :limit="query.variables.limit" :inc-limit="incLimit" :is-fetching="isFetching"></slot>
    </div>
</template>

<style lang="scss">
.dp__theme_light {
    --dp-primary-color: rgba(28, 29, 56, 1);
    --dp-border-radius: 0;
}
</style>
