<script setup>
import { computed, ref } from 'vue';
import { promiseTimeout } from '@vueuse/core';
import { useItineraryStore } from '@stores/itinerary';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const itinerary = useItineraryStore();

const loading = ref(false);

const addItem = async() => {
    loading.value = true;

    itinerary.addItem(props.item);

    await promiseTimeout(400);

    loading.value = false;
};

const isInItinerary = computed(() => {
    return itinerary.findItem(props.item);
});
</script>

<template>
    <button class="after:border-brandNavy" :class="{ 'loading': loading }" :disabled="loading" @click.prevent="addItem">
        <slot :is-in-itinerary="isInItinerary">
            Add to itinerary
        </slot>
    </button>
</template>
