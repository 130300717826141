<template>
    <Splide ref="splide">
        <slot :next-slide="nextSlide" :prev-slide="prevSlide" :go-to-slide="goToSlide"></slot>
    </Splide>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { Splide } from '@splidejs/vue-splide';

export default {
    name: 'SplideCarousel',

    components: {
        Splide,
    },

    setup() {
        const splide = ref(null);
        const index = ref(0);

        const prevSlide = () => {
            splide.value.go('<');
        };

        const nextSlide = () => {
            splide.value.go('>');
        };

        const goToSlide = (index) => {
            splide.value.go(index);
        };

        return {
            splide,
            index,
            prevSlide,
            nextSlide,
            goToSlide,
        };
    },
};
</script>
