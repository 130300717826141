<script setup>
import {
    ref, shallowRef, onMounted, watch,
} from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

const emit = defineEmits(['change']);

const props = defineProps({
    apiKey: {
        type: String,
        default: 'AIzaSyCv2HOM1E369ssDDg5iGfPVHmF0fnhv5zY',
    },
    markers: {
        type: Array,
        default: () => {
            return [];
        },
    },
    center: {
        type: Object,
        default: () => {
            return {
                lat: 0,
                lng: 0,
            };
        },
    },
    zoom: {
        type: Number,
        default: 12,
    },
    fitBounds: {
        type: Boolean,
        default: false,
    },
    hideLabels: {
        type: Boolean,
        default: false,
    },
    styles: {
        type: Array,
        default: () => {
            return [];
        },
    },
});

const mapEl = ref(null);

// Google Maps state
const map = shallowRef(null);
const mapMarkers = shallowRef([]);
const mapStyles = ref(props.styles);

const createMarker = (marker) => {
    let markerOptions = {
        id: marker.id,
        position: {
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
        },
        map: map.value,
    };

    if (marker.icon) {
        markerOptions = {
            ...markerOptions,
            icon: marker.icon,
        };
    }

    const mapMarker = new google.maps.Marker(markerOptions);

    mapMarker.addListener('click', () => {
        emit('change', marker);
    });

    return mapMarker;
};

const loader = new Loader({
    apiKey: props.apiKey,
    version: 'weekly',
});

onMounted(async() => {
    await loader.load();

    if (props.hideLabels) {
        mapStyles.value.push({
            featureType: 'poi',
            elementType: 'labels',
            stylers: [
                { visibility: 'off' },
            ],
        });
    }

    map.value = new google.maps.Map(mapEl.value, {
        center: {
            lat: parseFloat(props.center.lat),
            lng: parseFloat(props.center.lng),
        },
        zoom: props.zoom,
        styles: mapStyles.value,
    });

    if (!props.markers.length) {
        return;
    }

    const bounds = new google.maps.LatLngBounds();

    // Loop through all of our makers an add them to the map
    mapMarkers.value = props.markers.map((marker) => {
        return createMarker(marker);
    });
});


// Anytime the mapMarkers change update the map bounds
watch(mapMarkers, (mapMarkers) => {
    const bounds = new google.maps.LatLngBounds();

    mapMarkers.forEach((mapMarker) => {
        bounds.extend(mapMarker.getPosition());
    });

    if (props.fitBounds) {
        map.value.fitBounds(bounds);
    }
});

watch(() => {
    return props.markers;
}, async(markers) => {
    await loader.load();

    mapMarkers.value.forEach((mapMarker) => {
        mapMarker.setMap(null);
    });

    mapMarkers.value = [];

    mapMarkers.value = markers.map((marker) => {
        return createMarker(marker);
    });
});
</script>

<template>
    <div ref="mapEl"></div>
</template>
